import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route containers
import Widget from "./containers/pages/Widget";



// Route Views
import Errors from "./views/Errors";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/widget" />
  },
  {
    path: "/widget",
    layout: DefaultLayout,
    component: Widget
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
];
