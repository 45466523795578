import React from "react";

const WriteForm = () => (
    <div className="nd-review-form">
        <div className="nd-review-form-header">
            <div className="nd-review-form-tit">REVIEW</div>
            <div className="nd-btn-area">
                <a className="nd-btn-review-board" href="#">후기게시판</a>
            </div>
        </div>
        <div className="nd-review-form-body">
            <form className="nd-message-form">
                <div className="nd-message-field">
                    <textarea className="nd-message-textarea" maxLength="2000" cols="5000"></textarea>
                    <div className="nd-limit-counter">0</div>
                </div>
                <div className="nd-message-submit">
                    <div className="nd-attach-img">
                    <span className="nd-ico-attach">
                        <i className="nd-ico" data-feather="camera"></i>
                    </span>
                        <div className="nd-btn-attach-txt">+ 사진추가</div>
                    </div>
                    <div className="nd-select-score">
                        <div className="nd-select-score-wrap">
                            <a className="nd-select-score-container" href="#">
                        <span className="nd-chosen">
                            <div className="nd-score-box">
                                <div className="nd-score">
                                    <div className="nd-score-wrap">
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                    </div>
                                </div>
                                <div className="nd-score-txt">아주 좋아요</div>
                            </div>
                        </span>
                                <span className="nd-ico-arrow-down">
                            <i className="nd-ico" data-feather="chevron-down"></i>
                        </span>
                            </a>
                            <div className="nd-select-drop">
                                <ul className="nd-select-drop-wrap">
                                    <li className="nd-select-drop-item">
                                        <div className="nd-score-box">
                                            <div className="nd-score">
                                                <div className="nd-score-wrap">
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                </div>
                                            </div>
                                            <div className="nd-score-txt">아주 좋아요</div>
                                        </div>
                                    </li>
                                    <li className="nd-select-drop-item">
                                        <div className="nd-score-box">
                                            <div className="nd-score">
                                                <div className="nd-score-wrap">
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star empty"></span>
                                                </div>
                                            </div>
                                            <div className="nd-score-txt">맘에 들어요</div>
                                        </div>
                                    </li>
                                    <li className="nd-select-drop-item">
                                        <div className="nd-score-box">
                                            <div className="nd-score">
                                                <div className="nd-score-wrap">
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star empty"></span>
                                                    <span className="nd-score-star empty"></span>
                                                </div>
                                            </div>
                                            <div className="nd-score-txt">보통이에요</div>
                                        </div>
                                    </li>
                                    <li className="nd-select-drop-item">
                                        <div className="nd-score-box">
                                            <div className="nd-score">
                                                <div className="nd-score-wrap">
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star empty"></span>
                                                    <span className="nd-score-star empty"></span>
                                                    <span className="nd-score-star empty"></span>
                                                </div>
                                            </div>
                                            <div className="nd-score-txt">그냥 그래요</div>
                                        </div>
                                    </li>
                                    <li className="nd-select-drop-item">
                                        <div className="nd-score-box">
                                            <div className="nd-score">
                                                <div className="nd-score-wrap">
                                                    <span className="nd-score-star"></span>
                                                    <span className="nd-score-star empty"></span>
                                                    <span className="nd-score-star empty"></span>
                                                    <span className="nd-score-star empty"></span>
                                                    <span className="nd-score-star empty"></span>
                                                </div>
                                            </div>
                                            <div className="nd-score-txt">별로에요</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <button className="nd-btn-review-submit" type="submit">
                        <i className="nd-ico" data-feather="check-circle"></i>
                        리뷰 등록하기
                    </button>
                </div>
            </form>
        </div>
    </div>
);

export default WriteForm;
