import React from "react";

const Summary = () => (
    <div className="nd-review-summary">
        <div className="nd-review-summary-wrap">
            <div className="nd-review-summary-content">
                <div className="nd-review-summary-content-body">
                    <div className="nd-score-average">
                        <div className="nd-average-num">
                            4.8
                        </div>
                        <div className="nd-average-count">
                            300개 리뷰 평점
                        </div>
                    </div>
                    <div className="nd-score-filter">
                        <ul className="nd-score-filter-wrap">
                            <li className="nd-score-filter-item">
                                <a className="nd-score-link" href="#">
                                    <div className="nd-score-tit">5 Stars</div>
                                    <div className="nd-score-count">
                                        <div className="nd-score-num">(200)</div>
                                        <div className="nd-ico-check">
                                            <i className="nd-ico" data-feather="check"></i>
                                        </div>
                                    </div>
                                    <div className="nd-score-bar">
                                        <div className="nd-score-percent"></div>
                                    </div>
                                </a>
                            </li>
                            <li className="nd-score-filter-item">
                                <a className="nd-score-link" href="#">
                                    <div className="nd-score-tit">4 Stars</div>
                                    <div className="nd-score-count">
                                        <div className="nd-score-num">(50)</div>
                                        <div className="nd-ico-check">
                                            <i className="nd-ico" data-feather="check"></i>
                                        </div>
                                    </div>
                                    <div className="nd-score-bar">
                                        <div className="nd-score-percent"></div>
                                    </div>
                                </a>
                            </li>
                            <li className="nd-score-filter-item">
                                <a className="nd-score-link" href="#">
                                    <div className="nd-score-tit">3 Stars</div>
                                    <div className="nd-score-count">
                                        <div className="nd-score-num">(10)</div>
                                        <div className="nd-ico-check">
                                            <i className="nd-ico" data-feather="check"></i>
                                        </div>
                                    </div>
                                    <div className="nd-score-bar">
                                        <div className="nd-score-percent"></div>
                                    </div>
                                </a>
                            </li>
                            <li className="nd-score-filter-item">
                                <a className="nd-score-link" href="#">
                                    <div className="nd-score-tit">2 Stars</div>
                                    <div className="nd-score-count">
                                        <div className="nd-score-num">(10)</div>
                                        <div className="nd-ico-check">
                                            <i className="nd-ico" data-feather="check"></i>
                                        </div>
                                    </div>
                                    <div className="nd-score-bar">
                                        <div className="nd-score-percent"></div>
                                    </div>
                                </a>
                            </li>
                            <li className="nd-score-filter-item">
                                <a className="nd-score-link" href="#">
                                    <div className="nd-score-tit">1 Stars</div>
                                    <div className="nd-score-count">
                                        <div className="nd-score-num">(1)</div>
                                        <div className="nd-ico-check">
                                            <i className="nd-ico" data-feather="check"></i>
                                        </div>
                                    </div>
                                    <div className="nd-score-bar">
                                        <div className="nd-score-percent"></div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="nd-review-summary-content-footer">
                    <span className="nd-like-percentage">98%</span> 의 구매자들이 이 상품을 좋아합니다.
                    <span className="nd-like-user">( 400명 중 190명 )</span>
                </div>
            </div>
        </div>
    </div>
);

export default Summary;
