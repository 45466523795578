import React from "react";

const BasicList = () => (
    <div className="nd-review-wrap">
        <div className="nd-review-filter">
            <div className="nd-filter-sequence">
                <span className="nd-sequence-item selected">
                    <strong className="nd-sequence-tit">최신순</strong> 리뷰
                    <span className="nd-sequence-count">(233)</span>
                </span>
                <a className="nd-sequence-item" href="#">추천순</a>
                <a className="nd-sequence-item" href="#">평점순</a>
                <div className="nd-tooltip">
                    <div className="nd-tooltip-tit">
                        리뷰 정렬 기준
                        <i className="nd-ico" data-feather="info"></i>
                    </div>
                    <div className="nd-description">
                        <ul className="nd-description-wrap">
                            <li className="nd-description-item">
                                <div className="nd-description-tit">추천순</div>
                                <div className="nd-description-subs">
                                    쇼핑몰에서 추천한 리뷰와 고객들에게 도움이 많이 된 리뷰를 우선으로 노출합니다.
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="nd-bar-separator"></div>
            <div className="nd-filter-sort">
                <div className="nd-filter-sort-wrap">
                    <ul className="nd-sort-container">
                        <li className="nd-sort-item">
                            <div className="nd-sort-tit">
                                <div className="tit-txt">키</div>
                                <div className="nd-btn-dropdown">
                                    <i className="nd-ico" data-feather="chevron-down"></i>
                                </div>
                            </div>
                            <div className="nd-sort-drop">
                                <ul className="nd-sort-drop-wrap">
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_0"/>
                                        <label htmlFor="option_type_0">149 cm 이하</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_1"/>
                                        <label htmlFor="option_type_1">150 - 152 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_2"/>
                                        <label htmlFor="option_type_2">153 - 155 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_3"/>
                                        <label htmlFor="option_type_3">156 - 158 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_4"/>
                                        <label htmlFor="option_type_4">159 - 161 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_5"/>
                                        <label htmlFor="option_type_5">162 - 164 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_6"/>
                                        <label htmlFor="option_type_6">165 - 167 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_7"/>
                                        <label htmlFor="option_type_7">168 - 170 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_8"/>
                                        <label htmlFor="option_type_8">171 - 173 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_9"/>
                                        <label htmlFor="option_type_9">174 - 176 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_10"/>
                                        <label htmlFor="option_type_10">177 - 179 cm</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type_11"/>
                                        <label htmlFor="option_type_11">180 cm 이상</label>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nd-sort-item">
                            <div className="nd-sort-tit">
                                <div className="tit-txt">몸무게</div>
                                <div className="nd-btn-dropdown">
                                    <i className="nd-ico" data-feather="chevron-down"></i>
                                </div>
                            </div>
                            <div className="nd-sort-drop">
                                <ul className="nd-sort-drop-wrap">
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_0"/>
                                        <label htmlFor="option_type2_0">44 kg 이하</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_1"/>
                                        <label htmlFor="option_type2_1">45 - 47 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_2"/>
                                        <label htmlFor="option_type2_2">48 - 50 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_3"/>
                                        <label htmlFor="option_type2_3">51 - 53 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_4"/>
                                        <label htmlFor="option_type2_4">54 - 56 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_5"/>
                                        <label htmlFor="option_type2_5">57 - 59 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_6"/>
                                        <label htmlFor="option_type2_6">60 - 62 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_7"/>
                                        <label htmlFor="option_type2_7">63 - 65 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_8"/>
                                        <label htmlFor="option_type2_8">66 - 68 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_9"/>
                                        <label htmlFor="option_type2_9">69 - 71 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_10"/>
                                        <label htmlFor="option_type2_10">72 - 74 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_11"/>
                                        <label htmlFor="option_type2_11">75 - 77 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_12"/>
                                        <label htmlFor="option_type2_12">78 - 80 kg</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type2_13"/>
                                        <label htmlFor="option_type2_13">81 kg 이상</label>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nd-sort-item">
                            <div className="nd-sort-tit">
                                <div className="tit-txt">평소사이즈-상의</div>
                                <div className="nd-btn-dropdown">
                                    <i className="nd-ico" data-feather="chevron-down"></i>
                                </div>
                            </div>
                            <div className="nd-sort-drop">
                                <ul className="nd-sort-drop-wrap">
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type3_0"/>
                                        <label htmlFor="option_type3_0">XS</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type3_1"/>
                                        <label htmlFor="option_type3_1">S</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type3_2"/>
                                        <label htmlFor="option_type3_2">M</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type3_3"/>
                                        <label htmlFor="option_type3_3">L</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type3_4"/>
                                        <label htmlFor="option_type3_4">XL</label>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nd-sort-item">
                            <div className="nd-sort-tit">
                                <div className="nd-sort-tit-txt">평소사이즈-하의</div>
                                <div className="nd-btn-dropdown">
                                    <i className="nd-ico" data-feather="chevron-down"></i>
                                </div>
                            </div>
                            <div className="nd-sort-drop">
                                <ul className="nd-sort-drop-wrap">
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_0"/>
                                        <label htmlFor="option_type4_0">23</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_1"/>
                                        <label htmlFor="option_type4_1">24</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_2"/>
                                        <label htmlFor="option_type4_2">25</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_3"/>
                                        <label htmlFor="option_type4_3">26</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_4"/>
                                        <label htmlFor="option_type4_4">27</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_5"/>
                                        <label htmlFor="option_type4_5">28</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_6"/>
                                        <label htmlFor="option_type4_6">29</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_7"/>
                                        <label htmlFor="option_type4_7">30</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_8"/>
                                        <label htmlFor="option_type4_8">31</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_9"/>
                                        <label htmlFor="option_type4_9">32</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_10"/>
                                        <label htmlFor="option_type4_10">33</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_11"/>
                                        <label htmlFor="option_type4_11">34</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_12"/>
                                        <label htmlFor="option_type4_12">35</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_13"/>
                                        <label htmlFor="option_type4_13">36</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_14"/>
                                        <label htmlFor="option_type4_14">37</label>
                                    </li>
                                    <li className="nd-sort-drop-item">
                                        <input type="checkbox" className="sort-option" id="option_type4_15"/>
                                        <label htmlFor="option_type4_15">38</label>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ul className="nd-review-list">
            <li className="nd-review-item">
                <div className="nd-review-item-wrap">
                    <div className="nd-review-section right">
                        <div className="nd-review-info">
                            <ul className="nd-review-info-wrap">
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자</div>
                                    <div className="nd-review-info-val">이**</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성일</div>
                                    <div className="nd-review-info-val">2025.12.13</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자 등급</div>
                                    <div className="nd-review-info-val">회원</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nd-review-section left">
                        <div className="nd-review-evaluation">
                            <div className="nd-score-box">
                                <div className="nd-score">
                                    <div className="nd-score-wrap">
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                    </div>
                                </div>
                                <div className="nd-score-txt">- 아주 좋아요</div>
                            </div>
                            <div className="nd-help-summary">
                                <div className="nd-help-summary-wrap">
                                    (<strong>3</strong>명 중 <strong>3</strong>명이 도움이 된다고 선택했습니다.)
                                </div>
                            </div>
                        </div>
                        <div className="nd-review-section-bottom">
                            <div className="nd-review-container">
                                <div className="nd-review-option">
                                    <ul className="nd-option-box">
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">키</span>
                                            <span className="nd-option-val">160cm</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">몸무게</span>
                                            <span className="nd-option-val">50~55kg</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-상의</span>
                                            <span className="nd-option-val">M</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-하의</span>
                                            <span className="nd-option-val">29</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">선택한 옵션</span>
                                            <span className="nd-option-val">
                                <span className="nd-option-selected">색상 : <span className="nd-option-selected-val">블랙</span></span>
                                <span className="nd-option-selected">사이즈 : <span className="nd-option-selected-val">240</span></span>
                              </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nd-review-description">
                                    <div className="nd-review-contents">
                                        핏 너무 예뻐요! 재질도 좋고 색감도 생각했던 대로였어요. 마음에 듭니다.
                                        검수도 꼼꼼하게 하시는 듯 하고, 배송까지 빨랐습니다. 완벽 그 자체!
                                    </div>
                                    <div className="nd-btn-fold btn-view-more color-custom">..더보기</div>
                                </div>
                                <div className="nd-review-photo">
                                    <ul className="nd-review-photo-wrap">
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="nd-review-comment">
                                <div className="nd-comment-help">
                                    <div className="nd-btn-fold btn-add-reply color-custom">
                                        댓글을 작성해주세요.
                                    </div>
                                    <div className="nd-comment-help-tit">이 리뷰가 도움이 되었나요?</div>
                                    <div className="nd-comment-help-btn">
                                        <button type="button" className="nd-btn-evaluation"><span>네</span></button>
                                        <button type="button" className="nd-btn-evaluation"><span>아니요</span></button>
                                    </div>
                                    <div className="nd-comment-help-cnt">
                                        <span className="comment-help-cnt">3</span>
                                    </div>
                                </div>
                                <div className="nd-comment-reply">
                                    <div className="nd-comment-reply-wrap">
                                        <form method="post">
                                            <input className="nd-comment-reply-input" type="text" placeholder="댓글을 작성해주세요."/>
                                            <button className="nd-comment-reply-btn" type="submit">등록</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li className="nd-review-item">
                <div className="nd-review-item-wrap">
                    <div className="nd-review-section right">
                        <div className="nd-review-info">
                            <ul className="nd-review-info-wrap">
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자</div>
                                    <div className="nd-review-info-val">이**</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성일</div>
                                    <div className="nd-review-info-val">2025.12.13</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자 등급</div>
                                    <div className="nd-review-info-val">회원</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nd-review-section left">
                        <div className="nd-review-evaluation">
                            <div className="nd-score-box">
                                <div className="nd-score">
                                    <div className="nd-score-wrap">
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                    </div>
                                </div>
                                <div className="nd-score-txt">- 아주 좋아요</div>
                            </div>
                            <div className="nd-help-summary">
                                <div className="nd-help-summary-wrap">
                                    (<strong>3</strong>명 중 <strong>3</strong>명이 도움이 된다고 선택했습니다.)
                                </div>
                            </div>
                        </div>
                        <div className="nd-review-section-bottom">
                            <div className="nd-review-container">
                                <div className="nd-review-option">
                                    <ul className="nd-option-box">
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">키</span>
                                            <span className="nd-option-val">160cm</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">몸무게</span>
                                            <span className="nd-option-val">50~55kg</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-상의</span>
                                            <span className="nd-option-val">M</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-하의</span>
                                            <span className="nd-option-val">29</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">선택한 옵션</span>
                                            <span className="nd-option-val">
                                <span className="nd-option-selected">색상 : <span className="nd-option-selected-val">블랙</span></span>
                                <span className="nd-option-selected">사이즈 : <span className="nd-option-selected-val">240</span></span>
                              </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nd-review-description">
                                    <div className="nd-review-contents">
                                        핏 너무 예뻐요! 재질도 좋고 색감도 생각했던 대로였어요. 마음에 듭니다.
                                        검수도 꼼꼼하게 하시는 듯 하고, 배송까지 빨랐습니다. 완벽 그 자체!
                                    </div>
                                    <div className="nd-btn-fold btn-view-more color-custom">..더보기</div>
                                </div>
                                <div className="nd-review-photo">
                                    <ul className="nd-review-photo-wrap">
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="nd-review-comment">
                                <div className="nd-comment-help">
                                    <div className="nd-btn-fold btn-add-reply color-custom">
                                        댓글을 작성해주세요.
                                    </div>
                                    <div className="nd-comment-help-tit">이 리뷰가 도움이 되었나요?</div>
                                    <div className="nd-comment-help-btn">
                                        <button type="button" className="nd-btn-evaluation"><span>네</span></button>
                                        <button type="button" className="nd-btn-evaluation"><span>아니요</span></button>
                                    </div>
                                    <div className="nd-comment-help-cnt">
                                        <span className="comment-help-cnt">3</span>
                                    </div>
                                </div>
                                <div className="nd-comment-reply">
                                    <div className="nd-comment-reply-wrap">
                                        <form method="post">
                                            <input className="nd-comment-reply-input" type="text" placeholder="댓글을 작성해주세요."/>
                                            <button className="nd-comment-reply-btn" type="submit">등록</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li className="nd-review-item">
                <div className="nd-review-item-wrap">
                    <div className="nd-review-section right">
                        <div className="nd-review-info">
                            <ul className="nd-review-info-wrap">
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자</div>
                                    <div className="nd-review-info-val">이**</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성일</div>
                                    <div className="nd-review-info-val">2025.12.13</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자 등급</div>
                                    <div className="nd-review-info-val">회원</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nd-review-section left">
                        <div className="nd-review-evaluation">
                            <div className="nd-score-box">
                                <div className="nd-score">
                                    <div className="nd-score-wrap">
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                    </div>
                                </div>
                                <div className="nd-score-txt">- 아주 좋아요</div>
                            </div>
                            <div className="nd-help-summary">
                                <div className="nd-help-summary-wrap">
                                    (<strong>3</strong>명 중 <strong>3</strong>명이 도움이 된다고 선택했습니다.)
                                </div>
                            </div>
                        </div>
                        <div className="nd-review-section-bottom">
                            <div className="nd-review-container">
                                <div className="nd-review-option">
                                    <ul className="nd-option-box">
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">키</span>
                                            <span className="nd-option-val">160cm</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">몸무게</span>
                                            <span className="nd-option-val">50~55kg</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-상의</span>
                                            <span className="nd-option-val">M</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-하의</span>
                                            <span className="nd-option-val">29</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">선택한 옵션</span>
                                            <span className="nd-option-val">
                                <span className="nd-option-selected">색상 : <span className="nd-option-selected-val">블랙</span></span>
                                <span className="nd-option-selected">사이즈 : <span className="nd-option-selected-val">240</span></span>
                              </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nd-review-description">
                                    <div className="nd-review-contents">
                                        핏 너무 예뻐요! 재질도 좋고 색감도 생각했던 대로였어요. 마음에 듭니다.
                                        검수도 꼼꼼하게 하시는 듯 하고, 배송까지 빨랐습니다. 완벽 그 자체!
                                    </div>
                                    <div className="nd-btn-fold btn-view-more color-custom">..더보기</div>
                                </div>
                                <div className="nd-review-photo">
                                    <ul className="nd-review-photo-wrap">
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="nd-review-comment">
                                <div className="nd-comment-help">
                                    <div className="nd-btn-fold btn-add-reply color-custom">
                                        댓글을 작성해주세요.
                                    </div>
                                    <div className="nd-comment-help-tit">이 리뷰가 도움이 되었나요?</div>
                                    <div className="nd-comment-help-btn">
                                        <button type="button" className="nd-btn-evaluation"><span>네</span></button>
                                        <button type="button" className="nd-btn-evaluation"><span>아니요</span></button>
                                    </div>
                                    <div className="nd-comment-help-cnt">
                                        <span className="comment-help-cnt">3</span>
                                    </div>
                                </div>
                                <div className="nd-comment-reply">
                                    <div className="nd-comment-reply-wrap">
                                        <form method="post">
                                            <input className="nd-comment-reply-input" type="text" placeholder="댓글을 작성해주세요."/>
                                            <button className="nd-comment-reply-btn" type="submit">등록</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li className="nd-review-item">
                <div className="nd-review-item-wrap">
                    <div className="nd-review-section right">
                        <div className="nd-review-info">
                            <ul className="nd-review-info-wrap">
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자</div>
                                    <div className="nd-review-info-val">이**</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성일</div>
                                    <div className="nd-review-info-val">2025.12.13</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자 등급</div>
                                    <div className="nd-review-info-val">회원</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nd-review-section left">
                        <div className="nd-review-evaluation">
                            <div className="nd-score-box">
                                <div className="nd-score">
                                    <div className="nd-score-wrap">
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                    </div>
                                </div>
                                <div className="nd-score-txt">- 아주 좋아요</div>
                            </div>
                            <div className="nd-help-summary">
                                <div className="nd-help-summary-wrap">
                                    (<strong>3</strong>명 중 <strong>3</strong>명이 도움이 된다고 선택했습니다.)
                                </div>
                            </div>
                        </div>
                        <div className="nd-review-section-bottom">
                            <div className="nd-review-container">
                                <div className="nd-review-option">
                                    <ul className="nd-option-box">
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">키</span>
                                            <span className="nd-option-val">160cm</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">몸무게</span>
                                            <span className="nd-option-val">50~55kg</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-상의</span>
                                            <span className="nd-option-val">M</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-하의</span>
                                            <span className="nd-option-val">29</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">선택한 옵션</span>
                                            <span className="nd-option-val">
                                <span className="nd-option-selected">색상 : <span className="nd-option-selected-val">블랙</span></span>
                                <span className="nd-option-selected">사이즈 : <span className="nd-option-selected-val">240</span></span>
                              </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nd-review-description">
                                    <div className="nd-review-contents">
                                        핏 너무 예뻐요! 재질도 좋고 색감도 생각했던 대로였어요. 마음에 듭니다.
                                        검수도 꼼꼼하게 하시는 듯 하고, 배송까지 빨랐습니다. 완벽 그 자체!
                                    </div>
                                    <div className="nd-btn-fold btn-view-more color-custom">..더보기</div>
                                </div>
                                <div className="nd-review-photo">
                                    <ul className="nd-review-photo-wrap">
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="nd-review-comment">
                                <div className="nd-comment-help">
                                    <div className="nd-btn-fold btn-add-reply color-custom">
                                        댓글을 작성해주세요.
                                    </div>
                                    <div className="nd-comment-help-tit">이 리뷰가 도움이 되었나요?</div>
                                    <div className="nd-comment-help-btn">
                                        <button type="button" className="nd-btn-evaluation"><span>네</span></button>
                                        <button type="button" className="nd-btn-evaluation"><span>아니요</span></button>
                                    </div>
                                    <div className="nd-comment-help-cnt">
                                        <span className="comment-help-cnt">3</span>
                                    </div>
                                </div>
                                <div className="nd-comment-reply">
                                    <div className="nd-comment-reply-wrap">
                                        <form method="post">
                                            <input className="nd-comment-reply-input" type="text" placeholder="댓글을 작성해주세요."/>
                                            <button className="nd-comment-reply-btn" type="submit">등록</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li className="nd-review-item">
                <div className="nd-review-item-wrap">
                    <div className="nd-review-section right">
                        <div className="nd-review-info">
                            <ul className="nd-review-info-wrap">
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자</div>
                                    <div className="nd-review-info-val">이**</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성일</div>
                                    <div className="nd-review-info-val">2025.12.13</div>
                                </li>
                                <li className="nd-review-info-item">
                                    <div className="nd-review-info-tit">작성자 등급</div>
                                    <div className="nd-review-info-val">회원</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="nd-review-section left">
                        <div className="nd-review-evaluation">
                            <div className="nd-score-box">
                                <div className="nd-score">
                                    <div className="nd-score-wrap">
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                        <span className="nd-score-star"></span>
                                    </div>
                                </div>
                                <div className="nd-score-txt">- 아주 좋아요</div>
                            </div>
                            <div className="nd-help-summary">
                                <div className="nd-help-summary-wrap">
                                    (<strong>3</strong>명 중 <strong>3</strong>명이 도움이 된다고 선택했습니다.)
                                </div>
                            </div>
                        </div>
                        <div className="nd-review-section-bottom">
                            <div className="nd-review-container">
                                <div className="nd-review-option">
                                    <ul className="nd-option-box">
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">키</span>
                                            <span className="nd-option-val">160cm</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">몸무게</span>
                                            <span className="nd-option-val">50~55kg</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-상의</span>
                                            <span className="nd-option-val">M</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">평소사이즈-하의</span>
                                            <span className="nd-option-val">29</span>
                                        </li>
                                        <li className="nd-option-item">
                                            <span className="nd-option-tit">선택한 옵션</span>
                                            <span className="nd-option-val">
                                <span className="nd-option-selected">색상 : <span className="nd-option-selected-val">블랙</span></span>
                                <span className="nd-option-selected">사이즈 : <span className="nd-option-selected-val">240</span></span>
                              </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="nd-review-description">
                                    <div className="nd-review-contents">
                                        핏 너무 예뻐요! 재질도 좋고 색감도 생각했던 대로였어요. 마음에 듭니다.
                                        검수도 꼼꼼하게 하시는 듯 하고, 배송까지 빨랐습니다. 완벽 그 자체!
                                    </div>
                                    <div className="nd-btn-fold btn-view-more color-custom">..더보기</div>
                                </div>
                                <div className="nd-review-photo">
                                    <ul className="nd-review-photo-wrap">
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                        <li className="nd-review-photo-item">
                                            <a className="nd-photo-img" href="#">
                                                <img src="./img/img_transparent.png.jpg" alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="nd-review-comment">
                                <div className="nd-comment-help">
                                    <div className="nd-btn-fold btn-add-reply color-custom">
                                        댓글을 작성해주세요.
                                    </div>
                                    <div className="nd-comment-help-tit">이 리뷰가 도움이 되었나요?</div>
                                    <div className="nd-comment-help-btn">
                                        <button type="button" className="nd-btn-evaluation"><span>네</span></button>
                                        <button type="button" className="nd-btn-evaluation"><span>아니요</span></button>
                                    </div>
                                    <div className="nd-comment-help-cnt">
                                        <span className="comment-help-cnt">3</span>
                                    </div>
                                </div>
                                <div className="nd-comment-reply">
                                    <div className="nd-comment-reply-wrap">
                                        <form method="post">
                                            <input className="nd-comment-reply-input" type="text" placeholder="댓글을 작성해주세요."/>
                                            <button className="nd-comment-reply-btn" type="submit">등록</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div className="pagination-sec">
            <div className="pagination">
                <a className="pagination-prev">&#60;</a>
                <a className="pagination-btn active">1</a>
                <a className="pagination-btn">2</a>
                <a className="pagination-next">&#62;</a>
            </div>
        </div>
    </div>
);

export default BasicList;
