import { Common } from './Common';
import { User } from './User';
import { WebToApp } from './WebToApp';
import { Board } from "./Board";
import { Upload } from "./Upload";
import { Law } from "./Law";

class Store {
  constructor() {
    this.common = new Common(this);
    this.user = new User(this);
    this.webToApp = new WebToApp(this);
    this.board = new Board(this);
    this.upload = new Upload(this);
    this.law = new Law(this);
  }
}
export default Store;
