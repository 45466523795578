import React, {Component, Fragment} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';

import './style.scss';
import _ from "underscore";

import { Container } from "shards-react";
import WriteForm from "../../../components/write-form/WriteForm";
import Summary from "../../../components/summary/Summary";
import BasicList from "../../../components/list/BasicList";


@observer
class Widget extends Component {


  constructor(props) {
    super(props);

    this.init()
  }


  componentDidUpdate(prevProps, prevState){

  }

  @action
  init = async (key) => {

  };


  render() {
    return (
      <Container className="sample-container">
        <div className="nd-goodsview-basic-list">
          <WriteForm></WriteForm>
          <Summary></Summary>
          <BasicList></BasicList>
        </div>
      </Container>
    )
  }
}

export default Widget;
